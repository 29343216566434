import length from 'ramda/src/length'
import map from 'ramda/src/map'
import pick from 'ramda/src/pick'
import intersection from 'ramda/src/intersection'

import { assigneePlatformKey, platformIdKey } from 'root/src/shared/constants/keys'
import projectAssigneesSelector from 'root/src/client/logic/project/selectors/projectAssigneesSelector'
import tokensSelector from 'root/src/client/logic/api/selectors/tokensSelector'

export default (state, props) => {
	const assignees = projectAssigneesSelector(state, props)
	const userTokens = tokensSelector(state, props)
	const getTokenData = map(pick([assigneePlatformKey, platformIdKey]))
	return !!length(intersection(getTokenData(userTokens), getTokenData(assignees)))
}
