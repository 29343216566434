import { projectAcceptedKey, projectApprovedKey, projectDeliveryPendingKey, } from 'root/src/shared/constants/keys';
import { colorsNew } from 'root/src/client/web/commonStyles';
import { Platform } from 'root/src/shared/@types/platforms';
// eslint-disable-next-line import/prefer-default-export
export const allowPinDareStatuses = [
    projectAcceptedKey,
    projectDeliveryPendingKey,
    projectApprovedKey,
];
export const deliveryRatioThresholds = {
    0: 'Blocked. Stream or make a video for previous drops to enter more!',
    0.60: 'Stream or make a video for drops to maintain good standing!',
    0.75: 'Great Job, Keep It Up!',
};
export const deliveryRatioColor = {
    0: colorsNew.pink,
    0.60: colorsNew.yellow,
    0.75: colorsNew.green,
};
export const minDropsStartsToCount = 2;
export const MIN_ALLOWED_DELIVERY_RATIO = 0.60;
export const minDropsToValidateDeliveryRatioByPlatform = {
    [Platform.Twitch]: 2,
    [Platform.YouTube]: 2,
    [Platform.TikTok]: 0,
};
