import { SET_DARE_PINNED } from 'root/src/shared/descriptions/endpoints/endpointIds'
import apiRequest from 'root/src/client/logic/api/thunks/apiRequest'
import { apiStoreLenses } from 'root/src/client/logic/api/lenses'
import pipe from 'ramda/src/pipe'
import find from 'ramda/src/find'
import propEq from 'ramda/src/propEq'
import toPairs from 'ramda/src/toPairs'
import reduce from 'ramda/src/reduce'
import updateProjectRecord from 'root/src/client/logic/api/actions/updateProjectRecord'

const { viewProject } = apiStoreLenses

export default (projectId, isPinnedDare) => async (dispatch, getState) => {
	const state = getState()
	const { statusCode, error } = await dispatch(
		apiRequest(SET_DARE_PINNED, {
			id: projectId,
		}),
	)
	const success = statusCode === 200
	if (!success) throw error

	const { id: prevProjectId } =		pipe(
		viewProject,
		toPairs,
		reduce(
			(acc, [, projectProps]) => [
				...acc,
				{ ...projectProps },
			],
			[],
		),
		find(propEq('isPinnedDare', true)),
	)(state) || {}

	if (prevProjectId) {
		dispatch(
			updateProjectRecord({ id: prevProjectId, isPinnedDare: false }),
		)
	}
	dispatch(updateProjectRecord({ id: projectId, isPinnedDare: !isPinnedDare }))
}
