import reduce from 'ramda/src/reduce'
import isNil from 'ramda/src/isNil'
import equals from 'ramda/src/equals'
import any from 'ramda/src/any'
import getRecordSelector from 'root/src/client/logic/api/selectors/getRecordSelector'
import {
	projectAcceptedKey, projectDeliveryPendingKey, projectDeliveredKey,
} from 'root/src/shared/constants/keys'
import { amountRequestedProp } from 'root/src/shared/util/commonLenses'

export const getProjectGoalAmount = (project) => {
	const { assignees, status } = project
	const equalsStatus = equals(status)
	const accepted = any(equalsStatus)([
		projectAcceptedKey, projectDeliveryPendingKey, projectDeliveredKey,
	])
	if (accepted) {
		return reduce(
			(accum, assignee) => {
				const amountRequest = amountRequestedProp(assignee)
				return isNil(amountRequest) ? accum + 0 : accum + amountRequest
			},
			0,
			assignees,
		)
	}
	return 0
}

export default (state, props) => {
	const record = getRecordSelector(state, props)
	return getProjectGoalAmount(record)
}
