import apiRequest from 'root/src/client/logic/api/thunks/apiRequest'
import { ACCEPT_PROJECT, GET_USER_DATA } from 'root/src/shared/descriptions/endpoints/endpointIds'
import {
	VIEW_CREATOR_DATA_ROUTE_ID, VIEW_FACEBOOK_CREATOR_DATA_ROUTE_ID,
	VIEW_TIKTOK_CREATOR_DATA_ROUTE_ID,
	VIEW_YT_CREATOR_DATA_ROUTE_ID,
} from 'root/src/shared/descriptions/routes/routeIds'
import {
	ACCEPT_DARE_SUCCESS_MODULE_ID,
	DROP_IS_OVER_MODAL_MODULE_ID,
} from 'root/src/shared/descriptions/modules/moduleIds'
import platformSelector from 'root/src/client/logic/userData/selectors/platformSelector'
import prop from 'ramda/src/prop'
import mnemonicIdSelector from 'root/src/client/logic/userData/selectors/mnemonicIdSelector'
import pushRouteClient from 'root/src/client/logic/route/util/pushRouteClient'
import displayModal from 'root/src/client/logic/modal/actions/displayModal'
import mixpanel from 'root/src/client/logic/analytics/mixpanel'
import { mixpanelEvents } from 'root/src/shared/constants/mixpanel'
import { events } from 'root/src/shared/constants/analytics'
import analyticsSendItem from 'root/src/client/logic/analytics/util/analyticsSendItem'
import userIdSelector from 'root/src/client/logic/auth/selectors/userIdSelector'
import { displayUpdateContactInformationModal } from 'root/src/client/logic/project/helpers/displayUpdateContactInformationModal'
import accountDataSelector from 'root/src/client/logic/userData/selectors/accountDataSelector'

const routeMap = {
	twitch: {
		routeId: VIEW_CREATOR_DATA_ROUTE_ID,
		query: { modal: ACCEPT_DARE_SUCCESS_MODULE_ID },
	},
	youtube: {
		routeId: VIEW_YT_CREATOR_DATA_ROUTE_ID,
		query: { modal: ACCEPT_DARE_SUCCESS_MODULE_ID },
	},
	tiktok: {
		routeId: VIEW_TIKTOK_CREATOR_DATA_ROUTE_ID,
		query: { modal: ACCEPT_DARE_SUCCESS_MODULE_ID },
	},
	facebook: {
		routeId: VIEW_FACEBOOK_CREATOR_DATA_ROUTE_ID,
		query: { modal: ACCEPT_DARE_SUCCESS_MODULE_ID },
	},
}

export default projectUrl => async (dispatch, getState) => {
	const state = getState()

	const accountData = accountDataSelector(state)
	if (accountData.isConfirmEmailPromptEnabled) {
		const isConfirmed = await displayUpdateContactInformationModal()
		if (!isConfirmed) {
			return {}
		}
		await dispatch(apiRequest(GET_USER_DATA))
	}
	const { error } = await dispatch(apiRequest(ACCEPT_PROJECT, { projectUrl }))

	if (error) {
		if (error.code === 'BadRequest') {
			dispatch(displayModal(DROP_IS_OVER_MODAL_MODULE_ID))
		}
		throw error
	}

	const userId = userIdSelector(state)
	const platform = platformSelector(state)
	const { routeId, query } = prop(platform, routeMap)
	const mnemonicId = mnemonicIdSelector(state)
	mixpanel.track(mixpanelEvents.dropClaim)
	analyticsSendItem(events.acceptedDropDare, userId)
	return pushRouteClient({
		routeId,
		query,
		routeParams: { recordId: mnemonicId },
	})
}
