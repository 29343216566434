import getRecordSelector from 'root/src/client/logic/api/selectors/getRecordSelector'

import { GET_PROJECT } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { getResponseLenses } from 'root/src/shared/descriptions/getEndpointDesc'

import split from 'ramda/src/split'
import test from 'ramda/src/test'
import memoizeWith from 'ramda/src/memoizeWith'
import { renameKeys } from 'root/src/shared/util/ramdaPlus'

// eslint-disable-next-line no-restricted-imports
import urlParser from '@daredrop/js-video-url-parser'

import urlParserMod from 'root/src/shared/util/urlParser'

const responseLenses = getResponseLenses(GET_PROJECT)
const { viewDeliveries } = responseLenses

export const getProjectDelivery = (deliveries) => {
	const {
		videoURL, timeStamp: timestamp, thumbnail, embeddedContent, notesForReviewer,
	} = deliveries?.[0] || {}
	// urlParser doesn't support collection links
	const parsedUrl = urlParserMod(videoURL)
	// at the moment selector returns simply url of first delivery in deliveries array
	if (parsedUrl) {
		const playerUrl = urlParser.create({
			videoInfo: parsedUrl,
			format: parsedUrl.provider === 'embed',
		})
		const [hh, mm, ss] = split(':', timestamp)
		const startTime = `t=${hh}h${mm}m${ss}s`

		return {
			timestamp,
			thumbnail,
			timestampQuery: startTime,
			deliveryURL: `${playerUrl}${test(/.*\?.*/, playerUrl) ? '&' : '?'}${startTime}`,
			videoURL,
			embeddedContent,
			notesForReviewer,
			...renameKeys({
				provider:	'platform',
			}, parsedUrl),
		}
	}
	return null
}

export default memoizeWith(
	(state, props) => JSON.stringify(viewDeliveries(getRecordSelector(state, props))),
	(state, props) => getProjectDelivery(viewDeliveries(getRecordSelector(state, props))),
)
