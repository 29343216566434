import { modalContextImperative } from 'root/src/client/v2/common/contexts/modal.context';
import { UpdateEmailContactModal } from 'root/src/client/v2/auth/components/UpdateEmailContactModal';
export const displayUpdateContactInformationModal = () => new Promise((resolve) => {
    modalContextImperative.show(<UpdateEmailContactModal onConfirm={() => {
            modalContextImperative.hide();
            resolve(true);
        }} onCancel={() => {
            modalContextImperative.hide();
            resolve(false);
        }}/>, {
        isCloseable: false,
    });
});
