var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { memo } from 'react';
import { ModalLayout } from 'root/src/client/v2/common/layouts/ModalLayout';
import TextField from '@mui/material/TextField';
import { ButtonNew, ButtonNewVariant } from 'root/src/client/v2/common/components/form/ButtonNew';
import { routeUrls } from 'root/src/shared/descriptions/routes/routeUrls';
import { trpc } from 'root/src/client/v2/trpc/trpc';
import { useRouter } from 'next/router';
import { useAuthentication } from 'root/src/client/v2/auth/hooks/useAuthentication';
export const UpdateEmailContactModal = memo((props) => {
    var _a, _b;
    const router = useRouter();
    const email = (_b = (_a = useAuthentication()) === null || _a === void 0 ? void 0 : _a.authentication) === null || _b === void 0 ? void 0 : _b.email;
    const confirmEmailMutation = trpc.users.confirmEmail.useMutation();
    const onConfirm = () => __awaiter(void 0, void 0, void 0, function* () {
        yield confirmEmailMutation.mutateAsync();
        props.onConfirm();
    });
    const onCancel = () => __awaiter(void 0, void 0, void 0, function* () {
        yield confirmEmailMutation.mutateAsync();
        yield router.push(routeUrls.changeEmail());
        props.onCancel();
    });
    return (<ModalLayout className="gap-8">
			<h1 className="font-bold font-mono text-2xl">Email confirmation</h1>
			<div className="flex flex-col gap-2 justify-center items-center">
				<p>
					Your current email address
				</p>
				<TextField className="w-80" disabled InputProps={{
            readOnly: true,
        }} value={email}/>
			</div>
			<p className="font-medium">
				Do you want to keep this email address?
			</p>
			<div className="flex gap-8">
				<ButtonNew type="button" onClick={onCancel} variant={ButtonNewVariant.Ghost} disabled={confirmEmailMutation.isPending}>
					Change my email
				</ButtonNew>
				<ButtonNew type="button" onClick={onConfirm} disabled={confirmEmailMutation.isPending}>
					Keep my email
				</ButtonNew>
			</div>
		</ModalLayout>);
});
